import React, { Component } from "react";
import { PoiServices } from "../../api/PoiServices";
import RequestComponent from "../../../components/RequestComponent";

class PoiInfo extends Component {
  submitText = "poiInfo";
  parameters = [{ paramKey: "id", paramVal: "287182" }];

  doRequest = paramStates => {
    return PoiServices.poiInfo(paramStates.id);
  };

  render() {
    return (
      <RequestComponent
        parameters={this.parameters}
        submitText={this.submitText}
        doRequest={this.doRequest}
      />
    );
  }
}

export default PoiInfo;
