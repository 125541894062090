import React, { Component } from "react";
import Poi from "./Poi";
import PoiInfo from "./PoiInfo";
import PoiCategories from "./PoiCategories";

class PoiServiceComponent extends Component {
  render() {
    return (
      <>
        <hr />
        <span>
          <strong>Poi Service</strong>{" "}
        </span>
        <hr />
        <Poi />
        <hr />
        <PoiInfo />
        <hr />
        <PoiCategories />
        <hr />
      </>
    );
  }
}

export default PoiServiceComponent;
