import React, { Component } from "react";
import Cities from "./Cities";
import CityInfo from "./CityInfo";
import CityByCoordinate from "./CityByCoordinate";

class CitiesServiceComponent extends Component {
  render() {
    return (
      <>
        <hr />
        <span>
          <strong>Cities Service</strong>{" "}
        </span>
        <hr />
        <Cities />
        <hr />
        <CityInfo />
        <hr />
        <CityByCoordinate />
      </>
    );
  }
}

export default CitiesServiceComponent;
