import React, { Component } from "react";
import { PoiServices } from "../../api/PoiServices";
import RequestComponent from "../../../components/RequestComponent";

class PoiCategories extends Component {
  submitText = "poiCategories";
  parameters = [{ paramKey: "limit", paramVal: "6" }];

  doRequest = paramStates => {
    return PoiServices.poiCategories(paramStates.limit);
  };

  render() {
    return (
      <RequestComponent
        parameters={this.parameters}
        submitText={this.submitText}
        doRequest={this.doRequest}
      />
    );
  }
}

export default PoiCategories;
