import { RequestHelper } from "./RequestHelper";

export const CityServices = {
  cities: (
    q, // Search term (for example: "id:9,44|name:new") Columns: id, name
    search, // Search term for in city name
    limit // Record limit (Default: 20)
  ) =>
    RequestHelper.get("cities", {
      q,
      search,
      limit
    }),

  cityInfo: (
    id // city_id retuned from id field of cities calls
  ) => RequestHelper.get("cities/" + id),

  getCityByCoordinate: (
    coordinate // Coordinate (Format: latitude,longitude)
  ) => RequestHelper.get("getcitybycoordinate", { coordinate })
};
