import React, { Component } from "react";
import { PoiServices } from "../../api/PoiServices";
import RequestComponent from "../../../components/RequestComponent";

class Poi extends Component {
  submitText = "poi";
  parameters = [
    { paramKey: "city_id", paramVal: "40" },
    { paramKey: "coordinate", paramVal: "" },
    { paramKey: "search", paramVal: "" },
    { paramKey: "q", paramVal: "" },
    { paramKey: "poi_categories", paramVal: "1,2,3,4" },
    { paramKey: "distance", paramVal: "" },
    { paramKey: "fields", paramVal: "name,address,city" },
    { paramKey: "limit", paramVal: "20" }
  ];

  doRequest = paramStates => {
    return PoiServices.poi(
      paramStates.city_id,
      paramStates.coordinate,
      paramStates.search,
      paramStates.q,
      paramStates.poi_categories,
      paramStates.distance,
      paramStates.fields,
      paramStates.limit
    );
  };

  render() {
    return (
      <RequestComponent
        parameters={this.parameters}
        submitText={this.submitText}
        doRequest={this.doRequest}
      />
    );
  }
}

export default Poi;
