import React, { Component } from "react";
import { CityServices } from "../../api/CityServices";
import RequestComponent from "../../../components/RequestComponent";

class Cities extends Component {
  submitText = "cities";
  parameters = [
    { paramKey: "q", paramVal: "id:11,40|name:san" },
    { paramKey: "search", paramVal: "san" },
    { paramKey: "limit", paramVal: "20" }
  ];

  doRequest = paramStates => {
    return CityServices.cities(
      paramStates.q,
      paramStates.search,
      paramStates.limit
    );
  };

  render() {
    return (
      <RequestComponent
        parameters={this.parameters}
        submitText={this.submitText}
        doRequest={this.doRequest}
      />
    );
  }
}

export default Cities;
