import React from "react";

import CitiesServiceComponent from "./tripian/components/CitiesServiceComponent";
import PoiServiceComponent from "./tripian/components/PoiServiceComponent";

import "./App.css";

function App() {
  return (
    <div className="App">
      <p />
      {/* <Json2TableTest /> */}
      <CitiesServiceComponent />
      <PoiServiceComponent />
    </div>
  );
}

export default App;
