import axios from "axios";

const proxyUrl = "https://cors-anywhere.herokuapp.com/";
const apiUrl = "https://ybesi12ydk.execute-api.us-east-1.amazonaws.com/v02/";
const tokenKey =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjdkZWVlMmMwMmQwODJlNGNmZDRmNjI2OGY5N2VmZjI5YWU4M2Q1MWUyMjM4NThmMjU2NjFiMzkzYjUyNDljM2EyN2NkMWFjODk5OGQzZWIzIn0.eyJhdWQiOiIyIiwianRpIjoiN2RlZWUyYzAyZDA4MmU0Y2ZkNGY2MjY4Zjk3ZWZmMjlhZTgzZDUxZTIyMzg1OGYyNTY2MWIzOTNiNTI0OWMzYTI3Y2QxYWM4OTk4ZDNlYjMiLCJpYXQiOjE1NjYzMTI0NzIsIm5iZiI6MTU2NjMxMjQ3MiwiZXhwIjoxNTk3OTM0ODcyLCJzdWIiOiIyMDEiLCJzY29wZXMiOlsiKiJdfQ.jzmIvkvC2yEryFZi70qtlIFtWAa1v3Lv8VRrsNZkVNOLrbQGy9oHMtdvxIvcg9wvUlD8iTG-GSwaD8m3H37oac6iIdADbIP0h1pa4QdiqdpBadGD-B9IQTlnt9Tbx2Sy06hpLf1x8NRCAqbk2qn8LECRJoxtjTctx1bBEbDNJpo";
const xApiKey = "p3AhT0190A2OAvFPKN98b9gfkbNh12HH6nofhKfM";

export const RequestHelper = {
  get: (path, params) => {
    let options = {
      headers: {
        "x-api-key": xApiKey,
        Authorization: "Bearer " + tokenKey
      },
      params: params
    };
    return axios
      .get(proxyUrl + apiUrl + path, options)
      .then(function(response) {
        console.log(response.data);
        return response.data.data;
      })
      .catch(function(error) {
        console.log(error.data);
        return error.data;
      });
  },

  post: (path, data) => {
    let options = {
      headers: {
        "x-api-key": xApiKey,
        Authorization: "Bearer " + tokenKey
      }
    };
    return axios
      .post(proxyUrl + apiUrl + path, data, options)
      .then(function(response) {
        console.log(response.data);
        return response.data.data;
      })
      .catch(function(error) {
        console.log(error.data);
        return error.data;
      });
  }
};
