import React, { Component } from "react";
import { CityServices } from "../../api/CityServices";
import RequestComponent from "../../../components/RequestComponent";

class CityByCoordinate extends Component {
  submitText = "cityInfo";
  parameters = [{ paramKey: "coordinate", paramVal: "43.653226,-79.38318429" }];

  doRequest = paramStates => {
    return CityServices.getCityByCoordinate(paramStates.coordinate);
  };

  render() {
    return (
      <RequestComponent
        parameters={this.parameters}
        submitText={this.submitText}
        doRequest={this.doRequest}
      />
    );
  }
}

export default CityByCoordinate;
