import React, { Component } from "react";
import { CityServices } from "../../api/CityServices";
import RequestComponent from "../../../components/RequestComponent";

class CityInfo extends Component {
  submitText = "cityInfo";
  parameters = [{ paramKey: "id", paramVal: "40" }];

  doRequest = paramStates => {
    return CityServices.cityInfo(paramStates.id);
  };

  render() {
    return (
      <RequestComponent
        parameters={this.parameters}
        submitText={this.submitText}
        doRequest={this.doRequest}
      />
    );
  }
}

export default CityInfo;
