import { RequestHelper } from "./RequestHelper";

export const PoiServices = {
  poiCategories: (
    limit // Record limit (Default: 20)
  ) =>
    RequestHelper.get("poi-categories", {
      limit
    }),

  poi: (
    city_id, // true/false	City id is required if you are not using the coordinate parameter
    coordinate, //	true/false	Coordinate is required if you are not using the city_id parameter (Format: latitude,longitude)
    search, //	false	Search terms for in poi names or tags (for example "restaurant", "museum", "bar" etc.)
    q, //	false	Search terms (for example "name:park|city_id:159,144,160") Columns: id, city_id, name, description, address, icon(restaurant, museum etc.)
    poi_categories, //	false	Poi categories parameter cannot be used without using the coordinate parameter. Use "," for multiple category. For example: 1,2,3
    distance, //	false	Distance parameter value is in km. This parameter cannot be used without the coordinate parameter. Default value: 50(km)
    fields, //	false	Poi field list. For example: name,address,city
    limit //	false	Record limit (Default: 20)
  ) => {
    let params = {};
    params.city_id = city_id === null || city_id === "" ? null : city_id;
    params.coordinate =
      coordinate === null || coordinate === "" ? null : coordinate;
    params.search = search === null || search === "" ? null : search;
    params.q = q === null || q === "" ? null : q;
    params.poi_categories =
      poi_categories === null || poi_categories === "" ? null : poi_categories;
    params.distance = distance === null || distance === "" ? null : distance;
    params.fields = fields === null || fields === "" ? null : fields;
    params.limit = limit === null || limit === "" ? null : limit;

    return RequestHelper.get("poi", params);
  },

  poiInfo: (
    id // Place id
  ) => RequestHelper.get("poi/" + id)
};
